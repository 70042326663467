/*----------- Angular Material Themes -----------*/
@use '@angular/material' as mat;

@include mat.core();

$prophile-palette: (
    50: #dff4f0,
    100: #b1e3d8,
    200: #7ed1bf,
    300: #45bea6,
    400: #05af93,
    500: #00a081,
    600: #009374,
    700: #008264,
    800: #007256,
    900: #00563a,
    A100: #b8d7d0,
    A200: #93b9b3,
    A400: #54867f,
    A700: #205049,
    contrast: (
        50: #d7f2ef,
        100: #b8d7d0,
        200: #93b9b3,
        300: #6f9c95,
        400: #54867f,
        500: #38726a,
        600: #2f635b,
        700: #205049,
        800: #123d38,
        900: #022824
    )
);

$my-primary: mat.define-palette($prophile-palette, 500);
$my-accent: mat.define-palette($prophile-palette, A200, A100, A400);

$prophile-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
 ),
 border: (

 )
));

@include mat.all-component-themes($prophile-theme);

.mat-mdc-tab-header {
    --mat-tab-header-label-text-size: 16px;
}

nav.mat-mdc-tab-nav-bar {
    border-bottom: #cccccc solid 1px;
}
